<script>
import { mapActions } from "vuex";
import { format } from "date-fns";
import MainButton from "@/components/helpers/MainButton.vue";

export default {
  components: { MainButton },

  props: {
    organization: {
      type: Object,
      required: true,
    },

    cardType: {
      type: String,
      default: "deleting",
    },
  },

  data() {
    return {
      checkedCompany: false,
      companyAvailabilityStatus: "",
      checkingCompany: false,
      bookingCompany: false,

      companyExpiresAt: "",
    };
  },

  computed: {
    bookedCompanyExpiresAt() {
      return format(new Date(this.organization.expires_at), "dd.MM.yyyy");
    },
  },

  methods: {
    ...mapActions({
      checkCompany: "tsp/checkCompany",
      bookCompany: "tsp/bookCompany",
    }),

    checkCompanyRequest(inn) {
      this.checkingCompany = true;

      this.checkCompany({ inn: inn })
        .then(() => {
          this.companyAvailabilityStatus = "available";
          this.checkedCompany = true;
        })
        .catch((error) => {
          if (error.response.data.data) {
            this.companyExpiresAt = format(
              new Date(error.response.data.data.expires_at),
              "dd.MM.yyyy"
            );

            this.companyAvailabilityStatus = "not-available";
          } else {
            this.companyAvailabilityStatus = "already-use";
          }
          this.checkedCompany = true;
        })
        .finally(() => {
          this.checkingCompany = false;
        });
    },

    bookCompanyRequest(inn) {
      this.bookingCompany = true;

      this.bookCompany({ inn: inn })
        .then(() => {
          this.$emit("organisationBooked");
        })
        .finally(() => {
          this.bookingCompany = false;
        });
    },
  },
};
</script>

<template>
  <div class="organization-card">
    <button
      v-if="cardType === 'deleting'"
      class="button-icon"
      @click="$emit('delete', organization)"
    >
      <span class="organization-card__icon"></span>
    </button>

    <h4 class="organization-card__name">{{ organization.name }}</h4>
    <p class="organization-card__info">
      {{ organization.address }}
    </p>
    <p v-if="cardType === 'deleting'" class="organization-card__date">
      Действителен до: {{ bookedCompanyExpiresAt }}
    </p>

    <template v-if="cardType === 'adding'">
      <div class="organization-card__add adding">
        <main-button
          v-if="!checkedCompany"
          class="adding__button-check"
          :title="'Проверить доступность'"
          :color="'dark'"
          :padding="'13px 24px'"
          :loading="checkingCompany"
          @click="checkCompanyRequest(organization.inn)"
        />

        <main-button
          v-if="checkedCompany && companyAvailabilityStatus === 'available'"
          class="adding__button-add"
          :title="'Добавить в план'"
          :color="'dark'"
          :padding="'13px 24px'"
          :loading="bookingCompany"
          @click="bookCompanyRequest(organization.inn)"
        />

        <p
          v-if="checkedCompany && companyAvailabilityStatus === 'available'"
          class="adding__info"
        >
          Организация свободна и будет закреплена за вами на один месяц
        </p>

        <p
          v-if="checkedCompany && companyAvailabilityStatus === 'not-available'"
          class="adding__error"
        >
          Организация занята до {{ companyExpiresAt }}
        </p>

        <p
          v-if="checkedCompany && companyAvailabilityStatus === 'already-use'"
          class="adding__error"
        >
          Организация уже зарегистрирована
        </p>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.organization-card {
  background-color: $background-white;
  border-radius: 8px;
  padding: $space-m 48px $space-m $space-m;
  position: relative;

  &:not(:last-child) {
    margin-bottom: $space-m;
  }

  &__icon {
    mask: url("../assets/icons/close_bold.svg") 0 0 / cover no-repeat;
    display: inline-block;
    width: 24px;
    height: 24px;
    background: $dark-sixth;
    position: absolute;
    right: $space-m;
    top: $space-m;
  }

  &__name {
    @include body-1-bold;
    color: $dark-primary;
    margin-bottom: $space-xs;
  }

  &__info {
    @include text-2;
    color: $dark-fifth;
    text-transform: uppercase;
    margin-bottom: $space-s;
  }

  &__date {
    @include text-2;
    color: $dark-primary;
  }

  .adding {
    display: flex;
    align-items: center;

    &__button-check {
      max-width: 252px;
    }

    &__button-add {
      max-width: 200px;
      margin-right: $space-m;
    }

    &__info {
      @include text-2;
      color: $green;
      max-width: 330px;
    }

    &__error {
      @include text-2;
      color: $red;
    }
  }
}

@media (max-width: 576px) {
  .organization-card {
    .adding {
      flex-direction: column;
      align-items: flex-start;

      &__button-add {
        margin-right: 0;
        margin-bottom: $space-s;
      }
    }
  }
}
</style>
