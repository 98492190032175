<script>
import vClickOutside from "v-click-outside";
import MainButton from "@/components/helpers/MainButton.vue";

export default {
  components: {
    MainButton,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    organization: {
      type: Object,
      required: true,
    },
  },

  computed: {
    formattedTitle() {
      return `Удалить ${this.organization.name} из плана продаж?`;
    },
  },
};
</script>

<template>
  <div
    v-click-outside="() => $emit('close')"
    class="sales-plan-delete-organization"
  >
    <button class="button-icon" @click="$emit('close')">
      <span class="sales-plan-delete-organization__icon"></span>
    </button>

    <h2 class="sales-plan-delete-organization__title">
      {{ formattedTitle }}
    </h2>

    <p class="sales-plan-delete-organization__notice">
      Чтобы вернуть организацию в план, потребуется добавить ее заново, срок
      брони обновится
    </p>

    <div class="sales-plan-delete-organization__buttons">
      <main-button
        :title="'Удалить'"
        :color="'dark'"
        @click="$emit('delete', organization)"
      />
      <main-button
        :title="'Отмена'"
        :color="'outlined'"
        @click="$emit('close')"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sales-plan-delete-organization {
  padding: $space-xxl;
  background-color: $light-second;
  border-radius: $space-m;
  max-width: 518px;
  position: relative;

  &__icon {
    mask: url("../../assets/icons/close.svg") 0 0 / cover no-repeat;
    display: inline-block;
    width: 32px;
    height: 32px;
    background: $dark-sixth;
    position: absolute;
    right: $space-m;
    top: $space-m;
  }

  &__title {
    margin-bottom: $space-l;
    @include title-3;
    color: $dark-primary;
  }

  &__notice {
    margin-bottom: $space-l;
    @include body-1;
    color: $dark-primary;
  }

  &__buttons {
    display: flex;
    justify-content: flex-start;

    .main-button {
      max-width: 108px;

      &:not(:last-child) {
        margin-right: $space-m;
      }
    }
  }
}
</style>
